import React from "react"
import styled, { keyframes } from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { H1, Hr, P, H2 } from "../components/layout/Styles"
import { FaPowerOff } from "react-icons/fa"

const JumboBox = styled.div`
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: center; */
  text-align: center;
  width: 90%;
  margin: auto;
  padding: 1px;
  /* height: 100px; */
  background-color: #ff470f;
  border-radius: 30px;
  color: white;
`

const jump = keyframes`
  0% {
    transform: scale(1);
    box-shadow: 0 1px 2px rgba(0,0,0,.15);
  }
  100% {
    transform: scale(1.05);
    box-shadow: 0 4px 20px rgba(0,0,0,.1);
  }
`

const StartBtn = styled.a`
  display: flex;
  align-items: center;
  width: 350px;
  margin: 100px auto;
  justify-content: center;
  padding: 10px;
  text-decoration: none;
  color: white;
  font-weight: bold;
  /* background-color: ${({ bgColor }) => bgColor || "salmon"}; */
  background-color: #1f996a;
  outline: none;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  font-size: 2.5em;
  padding: 25px 0px;
  animation: ${jump} 0.4s ease infinite alternate;
`

const FifthPage = () => (
  <Layout>
    <SEO title="Page two" />
    <H1>Start Here</H1>
    <Hr style={{ backgroundColor: "salmon" }} />
    <JumboBox>
      <H2>Start using Eyechart.Online for Free!</H2>
      <P>
        Sign up for a 60 day free evaluation period with no obligation to renew.
      </P>
    </JumboBox>
    <StartBtn
      href="https://app.eyechart.online"
      target="_blank"
      rel="noopener noreferrer"
    >
      <FaPowerOff /> &nbsp; Start App
    </StartBtn>
    <P>
      Sign up for your free 60 day evaluation period to start using
      Eyechart.Online instantly. Renewal options are available from within the
      application once the 60 day Evaluation period has ended. Eyechart.Online
      is licenced on a monthly or yearly basis from as little as R330 per month
      for a yearly subscription or R390 per month for a monthly subscription.
      <span style={{ fontWeight: "bold", color: "salmon" }}>
        {" "}
        This works out to about R11 per day{" "}
      </span>
      for a state of the art Digital Chart and Diagnostic system, regularly
      updated, with full ongoing support. The return in terms of patient
      retention, practice modernisation, time savings and money savings is worth
      many many times that small investment.
    </P>
    {/* <Link to="/">Go back to the homepage</Link> */}
  </Layout>
)

export default FifthPage
